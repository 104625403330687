import React from "react";
import Transition from "./src/components/animations/transition";

export const wrapPageElement = ({ element, props }) => {
  return <Transition {...props}>{element}</Transition>;
};

const transitionDelay = 500

export const shouldUpdateScroll = ({
    routerProps: { location },
    getSavedScrollPosition,
}) => {
    if (location.action === 'PUSH') {
        window.setTimeout(() => window.scrollTo(0, 0), transitionDelay)
    } else {
        const savedPosition = getSavedScrollPosition(location)
        window.setTimeout(
            () => window.scrollTo(...(savedPosition || [0, 0])),
            transitionDelay
        )
    }
    return false
}

export const onServiceWorkerUpdateReady = () => {
    const answer = window.confirm(
      `Le site a été mis à jour !` +
        `Souhaitez-vous raffraichir la page pour la mettre à jour ?`
    )
    if (answer === true) {
      window.location.reload()
    }
}